
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index45EeGKu6nfMeta } from "/vercel/path0/pages/appointments/[id]/index.vue?macro=true";
import { default as createroLyFHETVoMeta } from "/vercel/path0/pages/appointments/create.vue?macro=true";
import { default as indexxsECH1DW5BMeta } from "/vercel/path0/pages/appointments/index.vue?macro=true";
import { default as edit3qa4ijxgfZMeta } from "/vercel/path0/pages/appointments/repair/[id]/edit.vue?macro=true";
import { default as indextiU270BID7Meta } from "/vercel/path0/pages/appointments/repair/[id]/index.vue?macro=true";
import { default as editGvjUXA00POMeta } from "/vercel/path0/pages/appointments/service/[id]/edit.vue?macro=true";
import { default as indexUV0ezhZI1RMeta } from "/vercel/path0/pages/appointments/service/[id]/index.vue?macro=true";
import { default as indexBDH30Z85iOMeta } from "/vercel/path0/pages/forgot/index.vue?macro=true";
import { default as successbNitMocYGSMeta } from "/vercel/path0/pages/forgot/success.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as confirmaFy9v9FWRoMeta } from "/vercel/path0/pages/order/confirm.vue?macro=true";
import { default as dateu6YajgJCjPMeta } from "/vercel/path0/pages/order/date.vue?macro=true";
import { default as indexQAMIjhY4dnMeta } from "/vercel/path0/pages/order/index.vue?macro=true";
import { default as success4OU86uzeDVMeta } from "/vercel/path0/pages/order/success.vue?macro=true";
import { default as indexLuCZoHS5xNMeta } from "/vercel/path0/pages/payments/index.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as profileSuccessriSCs7t9NhMeta } from "/vercel/path0/pages/settings/profileSuccess.vue?macro=true";
import { default as indexfT50SHwxXXMeta } from "/vercel/path0/pages/subscriptions/index.vue?macro=true";
import { default as indexoa8UIM335lMeta } from "/vercel/path0/pages/support/index.vue?macro=true";
import { default as reportb8i0vSQXpsMeta } from "/vercel/path0/pages/support/report.vue?macro=true";
import { default as successYa0Ghxwve8Meta } from "/vercel/path0/pages/support/success.vue?macro=true";
export default [
  {
    name: "appointments-id",
    path: "/appointments/:id()",
    meta: index45EeGKu6nfMeta || {},
    component: () => import("/vercel/path0/pages/appointments/[id]/index.vue")
  },
  {
    name: "appointments-create",
    path: "/appointments/create",
    meta: createroLyFHETVoMeta || {},
    component: () => import("/vercel/path0/pages/appointments/create.vue")
  },
  {
    name: "Appointments",
    path: "/appointments",
    meta: indexxsECH1DW5BMeta || {},
    component: () => import("/vercel/path0/pages/appointments/index.vue")
  },
  {
    name: "appointments-repair-id-edit",
    path: "/appointments/repair/:id()/edit",
    meta: edit3qa4ijxgfZMeta || {},
    component: () => import("/vercel/path0/pages/appointments/repair/[id]/edit.vue")
  },
  {
    name: "appointments-repair-id",
    path: "/appointments/repair/:id()",
    meta: indextiU270BID7Meta || {},
    component: () => import("/vercel/path0/pages/appointments/repair/[id]/index.vue")
  },
  {
    name: "appointments-service-id-edit",
    path: "/appointments/service/:id()/edit",
    meta: editGvjUXA00POMeta || {},
    component: () => import("/vercel/path0/pages/appointments/service/[id]/edit.vue")
  },
  {
    name: "appointments-service-id",
    path: "/appointments/service/:id()",
    meta: indexUV0ezhZI1RMeta || {},
    component: () => import("/vercel/path0/pages/appointments/service/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: indexBDH30Z85iOMeta || {},
    component: () => import("/vercel/path0/pages/forgot/index.vue")
  },
  {
    name: "forgot-success",
    path: "/forgot/success",
    meta: successbNitMocYGSMeta || {},
    component: () => import("/vercel/path0/pages/forgot/success.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "order-confirm",
    path: "/order/confirm",
    meta: confirmaFy9v9FWRoMeta || {},
    component: () => import("/vercel/path0/pages/order/confirm.vue")
  },
  {
    name: "order-date",
    path: "/order/date",
    meta: dateu6YajgJCjPMeta || {},
    component: () => import("/vercel/path0/pages/order/date.vue")
  },
  {
    name: "order",
    path: "/order",
    meta: indexQAMIjhY4dnMeta || {},
    component: () => import("/vercel/path0/pages/order/index.vue")
  },
  {
    name: "order-success",
    path: "/order/success",
    meta: success4OU86uzeDVMeta || {},
    component: () => import("/vercel/path0/pages/order/success.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexLuCZoHS5xNMeta || {},
    component: () => import("/vercel/path0/pages/payments/index.vue")
  },
  {
    name: "Settings",
    path: "/settings",
    meta: indexKIRFIU1ID4Meta || {},
    component: () => import("/vercel/path0/pages/settings/index.vue")
  },
  {
    name: "settings-profileSuccess",
    path: "/settings/profileSuccess",
    meta: profileSuccessriSCs7t9NhMeta || {},
    component: () => import("/vercel/path0/pages/settings/profileSuccess.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    meta: indexfT50SHwxXXMeta || {},
    component: () => import("/vercel/path0/pages/subscriptions/index.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexoa8UIM335lMeta || {},
    component: () => import("/vercel/path0/pages/support/index.vue")
  },
  {
    name: "support-report",
    path: "/support/report",
    meta: reportb8i0vSQXpsMeta || {},
    component: () => import("/vercel/path0/pages/support/report.vue")
  },
  {
    name: "support-success",
    path: "/support/success",
    meta: successYa0Ghxwve8Meta || {},
    component: () => import("/vercel/path0/pages/support/success.vue")
  }
]